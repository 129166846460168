<template>
	<w-layout row wrap>
		<w-flex :sm8="showDomain" xs12 px-1>
			<v-text-field
				v-model="accountingFirm.name"
				:disabled="disabled"
				:label="$t('admin_group.manager.accounting_firm_name')"
				maxlength="191"
				@input="$emit('input', accountingFirm)"
			/>
		</w-flex>
		<w-flex v-if="showDomain" sm4 xs12 px-1>
			<v-text-field disabled :label="$t('admin_group.manager.domain')" readonly :value="domain" />
		</w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'AccountingFirmForm',
	props: {
		disabled: {
			required: false,
			type: Boolean,
			default: false
		},
		showDomain: {
			required: false,
			type: Boolean,
			default: true
		},
		value: {
			required: false,
			type: Object,
			default: () => ({})
		}
	},
	data: function () {
		return {
			accountingFirm: {},
			domain: window.location.hostname
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (val) {
				this.accountingFirm = val
			},
			immediate: true
		}
	}
}
</script>
